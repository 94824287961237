import React, { useEffect, useRef, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Typography,
  Tooltip,
  FormControl,
  FormHelperText,
  Box,
  TextField,
  IconButton,
} from "@material-ui/core";
import { ExpandMore, Remove, Save, Undo } from "@material-ui/icons";
import plus from "../../../../../assets/img/plus.svg";
import ListItem from "../../../../../components/ListItem";
import InputField from "../../../../../components/FormFields/InputField";
import { removeIndexFromList } from "../../../../../utils/FormUtil";
import { Formik, useField } from "formik";
import * as Yup from "yup";
import { getMedicationSearch } from "../../../../../services/medicationService";
import { useStateAuthValue } from "../../../../../context/Auth/AuthState";
import AsyncCreatableSelect from "react-select/async-creatable";
import { id } from "date-fns/locale";
import { showToast, ToastType } from "../../../../../utils/ToastUtil";
import { useToast } from "../../../../../context/Toast/ToastProvider";
import { name } from "file-loader";

export default function Prescription({
  formField,
  parentValues,
  hasErrors,
  errors,
  setFieldValue,
  handleDisabled,
  patientFinish,
  setCustomFunc,
}) {
  const [{ userToken }] = useStateAuthValue();
  const { diagnosis, medications, other_indications } = formField;
  const [parentMedications, setParentMedications] = useState([]);
  const [medicationValue, setMedicationValue] = useState(null);
  const [posologyValue, setPosologyValue] = useState(null);
  const [indicationsLastSave, setIndicationsLastSave] = useState(null);
  const [indicationsLastText, setIndicationsLastText] = useState([
    { id: 0, text: "" },
  ]);
  const [indicationsOutOfBonds, setIindicationsOutOfBonds] = useState([
    { id: 0, isOutOfBonds: false },
  ]);

  const [field] = useField("Diagnosis");
  const toastDispatch = useToast();

  const showError = (msg) =>
    showToast(toastDispatch, msg, ToastType.ERROR, ToastType.ERROR);
  const showSucces = (msg) =>
    showToast(toastDispatch, msg, ToastType.SUCCESS, ToastType.SUCCESS);

  const initialValues = {
    diagnosis: field?.value || "",
    quantity: 1,
    medicationName: "",
    posology: null,
  };

  const removeOtherIndication = (index) => {
    if (index || index === 0) {
      const newList = removeIndexFromList(
        index,
        listOtherIndications,
        setListOtherIndications
      );
      setFieldValue("other_indications", newList);
      if (!newList.length) {
        handleDisabled(true);
      }
    }
  };

  const MedicationSchema = Yup.object().shape({
    diagnosis: Yup.string().required(diagnosis.requiredErrorMsg).nullable(),
    /* medicationName: Yup.string()
       .min(1, medications.medicationName.requiredErrorMsg)
       .nullable()
       .required(medications.medicationName.requiredErrorMsg)
        .test('unique', medications.uniqueMsg, function (value) {
          return (
           !parentMedications.length ||
           (value && !parentMedications.find((item) => item.name === value))
        );
        })
       ,*/
    posology: Yup.string()
      .max(200, "La posologia no puede superar los 200 caracteres.")
      .nullable(),
    quantity: Yup.number(medications.quantity.numberError)
      .required(medications.quantity.requiredErrorMsg)
      .nullable(),
  });

  const OtherIndicationSchema = Yup.object().shape({
    otherIndicationName: Yup.array().of(
      Yup.object().shape({
        id: Yup.number().required("El ID es requerido"),
        text: Yup.string()
          .min(1, "El texto no puede estar vacío")
          .required("El texto es requerido"),
      })
    ),
  });

  const addItem = (values, setField, validateForm) => {
    const name = medicationValue.label;
    if (name?.length) {
      if (
        parentMedications.length &&
        parentMedications.find((item) => item.name === name)
      ) {
        return;
      }
      const newList = [
        {
          id: Math.random().toString(), // NOSONAR
          diagnosis: values.diagnosis,
          primary: values.diagnosis,
          name: name,
          quantity: values.quantity,
          posology: posologyValue && posologyValue != "" ? posologyValue : null,
        },
        ...parentMedications,
      ];
      setFieldValue(medications.name, newList);
      setParentMedications(newList);
      handleDisabled(false);

      Object.keys(values).forEach((key) => setField(key, initialValues[key]));
      validateForm(initialValues);
      setMedicationValue(null);
      setPosologyValue("");
    }
  };

  const removeItem = (index) => {
    if (index || index === 0) {
      const newList = removeIndexFromList(
        index,
        parentMedications,
        setParentMedications
      );
      setFieldValue(medications.name, newList);

      if (!newList.length && !indicationsLastSave.length) {
        handleDisabled(true);
      }
    }
  };

  useEffect(() => {
    handleDisabled(hasErrors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasErrors]);

  useEffect(() => {
    if (!parentValues.medications) {
      setParentMedications([]);
      setFieldValue(medications.name, []);
    } else {
      setParentMedications(parentValues.medications);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentValues?.medications]);

  const selectCustomStyles = {
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      border: "none",
      borderBottom: "1px solid #6c6c6c",
      boxShadow: state.isFocused,
      background: "transparent",
      borderRadius: "none",
      fontSize: "12px",
      margin: "-6px 0 0 0",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#0c0c0c",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0",
    }),
    menuPortal: (base) => ({ ...base, zIndex: 1301 }),
    menu: (provided) => ({
      ...provided,
      fontSize: "12px",
    }),
  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      resolve(
        inputValue.length > 2
          ? getMedicationSearch({ description: inputValue }, userToken)
          : null
      );
    });

  const noOptionsMessage = (inputValue) => {
    if (inputValue && inputValue.inputValue.length < 3) {
      return "Debe ingresar al menos 3 caractes";
    } else {
      return "No se encontraron medicamentos";
    }
  };

  const textareaRef = useRef(null); // Estado para el ancho del textarea
  const simulationRef = useRef(null);
  const lineHeight = 24; // Altura de una línea en píxeles
  const maxRows = 27; // Límite máximo de filas bajo 40.5rem
  const maxSimulatedWidth = "40.5rem";

  const [textAreaStyle, setTextAreaStyle] = useState({
    border: "1px solid",
    borderColor: "rgba(0, 0, 0, 0.33)",
    maxWidth: "41rem",
    width: "90%",
    transition: "width 0.3s ease",
    //maxWidth: "40.5rem",
    minHeight: "20rem",
    maxHeight: "60rem",
    wordWrap: "break-word", // Fuerza que las palabras largas se ajusten.
    overflowWrap: "break-word", // Asegura que el texto no se salga del área.
    whiteSpace: "pre-wrap",
    resize: "none", // Previene el redimensionamiento manual
    padding: "1rem",
    fontFamily: "sans-serif",
    wordSpacing: ".5px",
  });

  const [saving, setSaving] = useState(false);
  const startTimerSaving = () => {
    setTimeout(() => {
      setSaving(false);
    }, 500);
  };
  const onChangeIndicationTextArea = (
    e,
    setField,
    index,
    currentArray,
    validateForm
  ) => {
    // if (saving) return;
    // setSaving(true);
    // startTimerSaving();
    const textarea = e.target;
    const simulation = simulationRef.current;

    // Simula el texto bajo 40.5rem
    simulation.textContent = textarea.value;

    const simulatedHeight = simulation.scrollHeight; // Altura simulada
    const textareaHeight = textarea.scrollHeight; // Altura simulada
    const maxSimulatedHeight = lineHeight * maxRows; // Altura máxima permitida

    // Restringir el texto si excede el límite simulado
    if (simulatedHeight > maxSimulatedHeight) {
      // setIindicationsOutOfBonds(
      //   indicationsOutOfBonds.map((item) =>
      //     item.id === index ? { ...item, isOutOfBonds: true } : item
      //   )
      // );
      textarea.classList.add("shake"); // Agrega la clase de animación
      textarea.parentElement.classList.add("showError"); // Agrega la clase de animación
      setTimeout(() => textarea.classList.remove("shake"), 400);
      setTimeout(
        () => textarea.parentElement.classList.remove("showError"),
        1000
      );
      textarea.value = indicationsLastText.find(
        (item) => item.id === index
      ).text;
    } else {
      //textarea.parentElement.classList.remove("showError");

      // setIindicationsOutOfBonds(
      //   indicationsOutOfBonds.map((item) =>
      //     item.id === index ? { ...item, isOutOfBonds: false } : item
      //   )
      // );
      setIndicationsLastText(
        indicationsLastText.map((item) =>
          item.id === index ? { ...item, text: textarea.value } : item
        )
      );
    }
    if (currentArray.length == 1 && textarea.value == "") {
      setFieldValue("other_indications", []);
      setField("otherIndicationName", [{ id: 0, text: "" }]);
      setIndicationsLastSave([]);

      if (!parentMedications.length) {
        handleDisabled(true);
      }
    } else {
      setField(
        "otherIndicationName",
        currentArray.map((item) =>
          item.id === index ? { ...item, text: textarea.value } : item
        )
      );
    }
  };

  useEffect(() => {
    setCustomFunc(() => () => {
      saveOtherIndication(indicationsLastText); 
    });
  }, [indicationsLastText]);

  const saveOtherIndication = (indicationList) => {
    if (isValidIndications(indicationList)) {
      const newList = indicationList.map(({ id, text, disabled }) => ({
        id,
        name: text,
        text: text,
        ...(disabled !== undefined && { disabled })
      }));
      setFieldValue("other_indications", newList);
      handleDisabled(false);
      setIndicationsLastSave(newList);
      //showSucces("Indicaciones guardadas correctamente.");
    } else {
      if (indicationList.length == 1) {
        setFieldValue("other_indications", []);
        handleDisabled(true);
        setIndicationsLastSave([]);
      } else {
        showError("Corregir errores en indicaciones antes de guardar.");
      }
    }
  };

  const isValidIndications = (indicationList) => {
    let isOneEmpty = false;
    let isOutOfBonds = false;

    indicationList?.map((indication) => {
      const foundItem = indicationsOutOfBonds.find(
        (item) => item.id === indication.id
      );
      if (
        indication.length ||
        indication.text == "" ||
        indication.text == "" ||
        !indication
      ) {
        isOneEmpty = true;
      }
      if (foundItem?.isOutOfBonds) {
        isOutOfBonds = true;
      }
    });

    return !(isOneEmpty || isOutOfBonds);
  };

  const handleDeleteLastIndication = (indicationList, setField) => {
    const newIndicationList = indicationList;
    const newindicationsOutOfBonds = indicationsOutOfBonds;
    const newIndicationsLastText = indicationsLastText;
    newIndicationList.pop();
    newindicationsOutOfBonds.pop();
    newIndicationsLastText.pop();
    setIindicationsOutOfBonds(newindicationsOutOfBonds);
    if (newIndicationList.length == 1 && newIndicationList[0].text == "") {
      setFieldValue("other_indications", []);
      setIndicationsLastSave([]);
      setField("otherIndicationName", [{ id: 0, text: "" }]);
      setIindicationsOutOfBonds([{ id: 0, isOutOfBonds: false }]);
      setIndicationsLastText([{ id: 0, text: "" }]);

      if (parentMedications.length == 0) {
        handleDisabled(true);
      }
    } else {
      setField("otherIndicationName", newIndicationList);
      saveOtherIndication(newIndicationList);
    }
  };
  const isIndicationEqualsToLastSave = (indicationList) => {
    if (
      !indicationsLastSave?.length &&
      indicationList.length &&
      indicationList[0].text.length
    ) {
      return false;
    } else if (!indicationList[0]?.text?.length) return true;
    let isEquals = true;
    indicationList.map((indication, index) => {
      if (
        !indicationsLastSave ||
        !indicationsLastSave.length ||
        !indicationsLastSave[index] ||
        indication.text != indicationsLastSave[index]?.name
      ) {
        isEquals = false;
      }
    });
    return isEquals;
  };
  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className="h1-head">Recetas</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Formik
            initialValues={initialValues}
            validationSchema={MedicationSchema}
            validateOnMount
            enableReinitialize
          >
            {({
              values,
              isValid,
              setFieldValue: setField,
              validateForm,
              validateField,
              errors: errorsMedication,
            }) => {
              if (!values?.diagnosis && parentValues?.diagnosis) {
                setField("diagnosis", parentValues.diagnosis);
                values.diagnosis = parentValues.diagnosis;
                validateField("diagnosis");
                //   validateField('medicationName');
              }

              return (
                <>
                  <Grid container spacing={1}>
                    <Grid item xs={10}>
                      <InputField
                        onKeyPress={(e) => {
                          if (e.key === "Enter") e.preventDefault();
                        }}
                        name="diagnosis"
                        label="Diagnostico *"
                        className="field"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={2} style={{ marginRight: "15px" }}>
                      <InputField
                        onKeyPress={(e) => {
                          if (e.key === "Enter") e.preventDefault();
                        }}
                        name="quantity"
                        label="Cantidad *"
                        className="field"
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <label className="label-medication">Medicamento *</label>
                      {
                        <AsyncCreatableSelect
                          cacheOptions
                          defaultOptions
                          loadOptions={promiseOptions}
                          onChange={(e) => {
                            setField("medicationName", e ? e.label : null);
                            values.medicationName = e ? e.label : null;
                            //validateField('medicationName');
                            setMedicationValue(e);
                          }}
                          value={medicationValue}
                          placeholder={"Buscar Medicamento"}
                          styles={selectCustomStyles}
                          noOptionsMessage={noOptionsMessage}
                          isClearable
                        />
                      }
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={10}>
                      {/* <InputField
                        onKeyPress={(e) => {
                          if (e.key === "Enter") e.preventDefault();
                        }}
                        name="posology"
                        label="Posología"
                        className="field"
                      /> */}
                      <TextField
                        label="Observación"
                        className="field"
                        error={errorsMedication.posology}
                        value={posologyValue}
                        onChange={(e) => {
                          setPosologyValue(e.target.value);
                          setField("posology", e.target.value);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") e.preventDefault();
                        }}
                        helperText={errorsMedication.posology}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Tooltip title="Agregar medicamento">
                        <Button
                          width={40}
                          type="button"
                          fullWidth={false}
                          disabled={isValid && medicationValue ? false : true}
                          className="plus-button"
                          onClick={() => {
                            addItem(values, setField, validateForm);
                          }}
                        >
                          <img src={plus} alt="plus" />
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <div className="pt-10">
                    <ListItem
                      data={parentMedications}
                      removeItem={removeItem}
                      removeTooltip="Eliminar medicamento"
                      noDataMessage=""
                      required={true}
                      errors={errors}
                      label={medications.name}
                      className="field"
                      displayKeys={["name", "posology"]}
                    />
                  </div>
                </>
              );
            }}
          </Formik>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className="h1-head" component={"span"}>
            Indicaciones
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography component={"span"}>
            <Formik
              initialValues={{ otherIndicationName: [{ id: 0, text: "" }] }}
              validationSchema={OtherIndicationSchema}
              validateOnMount
            >
              {({
                otherIndicationNameErrors,
                values,
                setFieldValue,
                errors,
                validateForm,
                submitForm,
              }) => {
                useEffect(() => {
                  console.log(parentValues?.other_indications)
                  if (parentValues?.other_indications && parentValues?.other_indications?.length != 0) {
                    setFieldValue("otherIndicationName", [...parentValues?.other_indications]);
                    setIndicationsLastSave([...parentValues?.other_indications]);
                    setIindicationsOutOfBonds(
                      parentValues?.other_indications.map((item) => ({
                        ...item,
                        isOutOfBonds: false,
                      }))
                    );
                  }
                }, [parentValues?.other_indications]);
                return (
                  <Grid container spacing={1} className="pt-10">
                    {values.otherIndicationName.map((otherIndicationName) => {
                      return (
                        <Box
                          display={"Flex"}
                          alignItems={"center"}
                          width={"100%"}
                          justifyContent={"space-between"}
                          flexDirection={"row"}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center", // Centrado si es necesario
                              width: "100%", // Ajusta el ancho del contenedor
                              height: "100%",
                              gap: "1rem",
                              flexDirection: "column", // O un tamaño fijo si es necesario, por ejemplo, "50vh"
                            }}
                            className="otherIndicationNameContainer"
                            key={otherIndicationName.id}
                          >
                            <textarea
                              disabled={otherIndicationName.disabled}
                              name="otherIndicationName"
                              label={other_indications.label}
                              value={otherIndicationName.text}
                              ref={textareaRef}
                              onChange={(e) => {
                                onChangeIndicationTextArea(
                                  e,
                                  setFieldValue,
                                  otherIndicationName.id,
                                  values.otherIndicationName,
                                  validateForm
                                );
                              }}
                              style={textAreaStyle}
                            ></textarea>
                            {(otherIndicationName.id != 0 || values?.otherIndicationName?.length > 1 ) &&
                            !otherIndicationName?.text?.length ? (
                              <span>La indicacion no puede estar vacia.</span>
                            ) : (
                              <></>
                            )}
                            <p>Limite de pagina alcanzado</p>
                            
                          </Box>
                        </Box>
                      );
                    })}

                    <Box
                      sx={{
                        display: "flex",
                        gap: "1rem",
                        flexWrap: "wrap",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        width={40}
                        type="button"
                        fullWidth={false}
                        disabled={
                          !!otherIndicationNameErrors?.otherIndicationName ||
                          !values.otherIndicationName
                        }
                        className="save-button indications-buttons"
                        onClick={() => {
                          let id = Math.random() * 100;
                          setIindicationsOutOfBonds([
                            ...indicationsOutOfBonds,
                            { id, isOutOfBonds: false },
                          ]);
                          setFieldValue("otherIndicationName", [
                            ...values.otherIndicationName,
                            { id, text: "" },
                          ]);
                          setIndicationsLastText([
                            ...indicationsLastText,
                            { id, text: "" },
                          ]);
                        }}
                      >
                        Agregar Pagina
                      </Button>
                      <Button
                        disabled={values.otherIndicationName?.every(item => item?.disabled === true) || values.otherIndicationName?.length <= 1}
                        type="button"
                        fullWidth={false}
                        className="save-button indications-buttons"
                        onClick={() => {
                          handleDeleteLastIndication(
                            values.otherIndicationName,
                            setFieldValue
                          );
                        }}
                      >
                        Quitar Pagina
                      </Button>
                      <Button
                        type="submit"
                        fullWidth={false}
                        disabled={
                          !isValidIndications(values.otherIndicationName)
                        }
                        className={`save-button indications-buttons ${
                          isIndicationEqualsToLastSave(
                            values.otherIndicationName
                          ) == true && "saved"
                        }`}
                        onClick={() => {
                          saveOtherIndication(values.otherIndicationName);
                        }}
                      >
                        Guardar Indicaciones
                      </Button>
                      {isIndicationEqualsToLastSave(
                        values.otherIndicationName
                      ) == false && (
                        <Box color={"red"}>Indicaciones no guardadas</Box>
                      )}
                    </Box>
                    {/* Contenedor invisible para simulación */}
                    <div
                      ref={simulationRef}
                      style={{
                        visibility: "hidden ",
                        position: "absolute",
                        background: "white",
                        top: 10,
                        right: 10,
                        zIndex: "10",
                        ...textAreaStyle,
                        width: maxSimulatedWidth,
                      }}
                    />
                  </Grid>
                );
              }}
            </Formik>
          </Typography>
        </AccordionDetails>
      </Accordion>
      {!parentMedications?.length && !!errors && (
        <FormControl error={!!errors}>
          <FormHelperText>{errors}</FormHelperText>
        </FormControl>
      )}
    </div>
  );
}
